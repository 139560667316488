import classnames from 'classnames'
import React from 'react'
import style from './style.module.css'
import typography from './../../typography.module.css'
import data from './../../data/home/pricing.yaml'

const PricingFeatures = () => (
  <div className={style.pricingfeatures}>
    <ul className={style.pricingfeatures__list}>
      {data.pricing_features.map((feature, key) => (
        <li key={key} className={style.pricingfeatures__item}>
          {feature}
        </li>
      ))}
    </ul>
  </div>
)

const PricingTag = () => (
  <div className={style.pricingtag}>
    <div
      className={classnames([style.pricingtag__caption, typography.tinyTitle])}
    >
      {data.pricing_tag__monthly_headline}
    </div>
    <div
      className={classnames([style.pricingtag__price, typography.paragraph96])}
    >
      {data.pricing_tag__monthly}
    </div>
    <div
      className={classnames([style.pricingtag__caption, typography.tinyTitle])}
    >
      {data.pricing_tag__monthly_caption}
    </div>
    <p className={classnames([style.pricingtag__price__discount])}>
      {data.pricing_tag__discount}
    </p>
  </div>
)

const Pricing = () => (
  <div id="pricing" className={style.pricing}>
    <div className={style.headline}>
      <h3 className={classnames([style.headline__title, typography.subtitle])}>
        {data.title}
      </h3>
      <div
        className={classnames([
          style.headline__description,
          typography.subtitle__caption,
        ])}
      >
        {data.description}
      </div>
    </div>
    <div className={style.details}>
      <PricingTag />
      <PricingFeatures />
    </div>
  </div>
)

export default Pricing
