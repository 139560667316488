import placeholder from './placeholder.svg'
import sebrae from './sebrae.png'
import redfoot from './redfoot.png'
import acil from './acil.png'
import acim from './acim.png'
import founderInstitute from './founder-institute.png'
import juntus from './juntus.png'

export default {
  placeholder,
  sebrae,
  redfoot,
  acil,
  acim,
  founderInstitute,
  juntus
}
