import classnames from 'classnames'
import React from 'react'
import style from './style.module.css'
import typography from './../../typography.module.css'
import data from './../../data/home/proposal.yaml'
import images from '../../images/home/proposal'

const ProposalImages = (image, index) => (
  <div className={style.images__item} key={`proposal-image-` + index}>
    <img src={images[image.src]} alt={image.title} />
  </div>
)

const Proposal = () => (
  <div className={style.proposal}>
    <div className={style.images}>{data.images.map(ProposalImages)}</div>
    <div className={style.texts}>
      <h2 className={classnames([style.title, typography.title])}>
        {' '}
        {data.title}{' '}
      </h2>
      <p
        className={classnames([style.descriptions, typography.title__caption])}
      >
        {data.description}
      </p>
    </div>
  </div>
)

Proposal.propTypes = {}

export default Proposal
