import classnames from 'classnames'
import React from 'react'
import style from './style.module.css'
import typography from './../../typography.module.css'
import data from './../../data/home/partners.yaml'
import images from './../../images/home/partners'

const Partners = () => (
  <div id="partners" className={style.partners}>
    <div className={style.heading}>
      <h3 className={classnames([style.title, typography.subtitle])}>
        {data.title}
      </h3>
    </div>
    <ul className={style.partners__list}>
      {data.partners.map((partner, index) => (
        <li className={style.partners__item} key={`partner-item-${index}`}>
          <img
            className={style.partners__item__image}
            src={images[partner.image]}
            alt={partner.title}
          />
        </li>
      ))}
    </ul>
  </div>
)

export default Partners
