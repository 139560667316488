import classnames from 'classnames'
import React from 'react'
import style from './style.module.css'
import typography from './../../typography.module.css'
import data from './../../data/home/that-simple.yaml'
import TryButton from '../try-button'

const ThatSimple = () => (
  <div id="that-simple" className={style.thatsimple}>
    <h3 className={classnames([style.title, typography.title])}>
      {data.title}
    </h3>
    <div className={style.thatsimple__action}>
      <TryButton label={data.label} />
    </div>
  </div>
)

export default ThatSimple
