import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Proposal from '../components/proposal'
import WeOffer from '../components/we-offer'
import HowItWork from '../components/how-it-work'
import CustomerCases from '../components/customer-cases'
import Pricing from '../components/pricing'
import Partners from '../components/partners'
import ThatSimple from '../components/that-simple'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Site e E-mail Corporativo para o Seu Negócio"
      description={'Tenha visibilidade e credibilidade online e conquiste mais clientes.'}
      mainDescription={'Site e E-mail Corporativo Para o Seu Negócio | entreinaweb'}
      keywords={['Entreinaweb', 'Web', 'Site', 'Online']}
    />
    <Proposal />
    <WeOffer />
    <HowItWork />
    <CustomerCases />
    <Pricing />
    <Partners />
    <ThatSimple />
  </Layout>
)

export default IndexPage
