import classnames from 'classnames'
import React from 'react'
import style from './style.module.css'
import typography from './../../typography.module.css'
import data from './../../data/home/customer-cases.yaml'
import images from '../../images/home/customer-cases'

const CustomerReview = ({ from, site, review, image }, index) => (
  <li className={style.reviews__item} key={`customer-cases-item-${index}`}>
    <span className={classnames([style.reviews__quote, typography.title])}>
      “
    </span>
    <p
      className={classnames([
        style.reviews__item__review,
        typography.paragraph16,
      ])}
    >
      {review}
    </p>
    <div className={style.reviews__item__avatar}>
      <img
        className={style.reviews__item__avatar__image}
        src={images[image]}
        alt={from}
      />
      <div className={style.reviews__item__avatar__texts}>
        <h4
          className={classnames([
            style.reviews__item__from,
            typography.xsTitle,
          ])}
        >
          {from}
        </h4>
        <span
          className={classnames([
            style.reviews__item__site,
            typography.paragraph14,
          ])}
        >
          {site}
        </span>
      </div>
    </div>
  </li>
)

const CustomerCases = () => (
  <div id="cases" className={style.cases}>
    <h3 className={classnames([style.title, typography.subtitle])}>
      {data.title}
    </h3>
    <p
      className={classnames([
        style.title_caption,
        typography.subtitle__caption,
      ])}
    >
      {data.description}
    </p>
    <div className={style.reviews} />
    <ul className={style.reviews__list}>{data.reviews.map(CustomerReview)}</ul>
    <div className={style.tiles}>
      <img
        className={style.image}
        src={images.customers}
        alt="Nossos clientes"
      />
    </div>
  </div>
)

export default CustomerCases
