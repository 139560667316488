import React from 'react'
import style from './style.module.css'
import routes from '../../data/routes.yaml'
/*
 * this component should scale according to it font size only
 * */
const TryButton = ({ label = 'Experimentar Grátis' }) => (
  <a href={routes.register} className={style.button}>
    {label}
  </a>
)

export default TryButton
