import classnames from 'classnames'
import React from 'react'
import style from './style.module.css'
import typography from './../../typography.module.css'
import data from './../../data/home/we-offer.yaml'
import images from '../../images/home/we-offer'

const WeOfferFeaturesItem = ({ title, image, description }, index) => (
  <li className={style.features__item} key={`we-offer-feature-item-${index}`}>
    <img
      className={style.features__item__image}
      src={images[image]}
      alt={title}
    />
    <h4
      className={classnames([
        style.features__item__title,
        typography.cardTitle,
      ])}
    >
      {title}
    </h4>
    <p
      className={classnames([
        style.features__item__description,
        typography['paragraph--20'],
      ])}
    >
      {description}
    </p>
  </li>
)

const WeOfferFeatures = () => (
  <div className={style.features}>
    <ul className={style.features__list}>
      {data.features.map(WeOfferFeaturesItem)}
    </ul>
  </div>
)

const WeOffer = () => (
  <div id="weoffer" className={style.weoffer}>
    <h3 className={classnames([style.title, typography.subtitle])}>
      {data.title}
    </h3>
    <p
      className={classnames([
        style.title_caption,
        typography.subtitle__caption,
      ])}
    >
      {data.description}
    </p>
    <WeOfferFeatures />
  </div>
)

WeOffer.propTypes = {}

export default WeOffer
