import classnames from 'classnames'
import React from 'react'
import style from './style.module.css'
import typography from './../../typography.module.css'
import data from './../../data/home/how-it-work.yaml'
import TryButton from '../try-button'

const StepsItem = (step, index) => (
  <li
    className={classnames([style.steps__list__item])}
    key={`how-it-work-step-` + index}
  >
    <div
      className={classnames([
        style.steps__list__item__title,
        typography.smallTitle,
      ])}
    >
      {step.title}
    </div>
    <div className={style.steps__list__item__description}>
      {step.description}
    </div>
  </li>
)

const HowItWork = () => (
  <div className={style.how} id="how-it-work">
    <section className={style.how__section}>
      <div className={classnames([style.how__section__item, style.headline])}>
        <div className={style.headline__container}>
          <h3
            className={classnames([style.headline__title, typography.subtitle])}
          >
            {data.title}
          </h3>
          <p
            className={classnames([
              style.headline__description,
              typography.paragraph20,
            ])}
          >
            {data.description}
          </p>
        </div>
      </div>
      <div className={classnames([style.how__section__item, style.steps])}>
        <div className={style.steps__list}>{data.steps.map(StepsItem)}</div>
      </div>
    </section>
    <footer className={style.footer}>
      <TryButton />
    </footer>
  </div>
)

export default HowItWork
