import customers from './customers.svg'
import caio from './caio.png'
import tatiane from './tatiane.png'
import lauren from './lauren.png'

export default {
  customers,
  // below section is customers avatars
  caio,
  tatiane,
  lauren,
}
