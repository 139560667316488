import website from './website.svg'
import host from './host.svg'
import domain from './domain.svg'
import email from './email.svg'

export default {
  website,
  host,
  domain,
  email,
}
